<template>
<div>
    <van-skeleton title  :row="3" :loading="loading">
        <div><TopHeader></TopHeader></div>
        <div> <TopSearch></TopSearch></div>
        <div><TopBanner :banners="bannerList"></TopBanner></div>
        <div><AppList :colNum="4" :apps="apps" :fetchList="refreshAppList"></AppList></div>
        
    </van-skeleton>
    <van-skeleton title avatar :row="8" :loading="loading"/>
    <br/>
    <p>&nbsp;</p>
</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
import TopSearch from '@/components/TopSearch.vue'
import TopBanner   from '@/components/TopBanner.vue';
import AppList from '@/components/AppList.vue';
import HttpHelper from '@/utils/HttpHelper';
import GlobalConfig from '@/utils/GlobalConfig';
import Utils from '@/utils/Utils';

export default {
    name:"AppsView",
    data() {
        return {
            loading:true,
            apps:[],
            reported: false,
            timeId:null,
            bannerList:[]
        }
    },

    components:{
        TopSearch,
        TopHeader,
        TopBanner,
        AppList
    },

    computed:{
        banners(){
            return GlobalConfig.getInstance().banners;
        }
    },

    methods:{
        finishLoad() {
            this.loading = false;
        },

        refreshBanner(){
            HttpHelper.queryBannerList(Utils.getDevNo(), (code, data) =>{
            if (code != 200) {
                console.log("query banner fail ", code)
                return;
            }

            while(this.bannerList.shift());
            while(GlobalConfig.getInstance().banners.shit);

            data.forEach((ele)=>{
                this.bannerList.push(ele);
                GlobalConfig.getInstance().banners.push(ele);
            })
          });
        },

        refreshAppList(cb) {
          HttpHelper.queryAppList(Utils.getDevNo(), (code, data) =>{
            if (code != 200) {
                console.log("query app fail ", code)
                return;
            }

            if (data && data.length > 0) {
                var iconAd = [];
                for (var i = 0; i < data.length; i++){
                    if (data[i].kind == 1) {
                        iconAd.push(data[i]);
                    }
                }

                while(GlobalConfig.getInstance().iconAdverts.shift());

                data.forEach((ele)=>{
                    GlobalConfig.getInstance().iconAdverts.push(ele);
                })
                
                while(this.apps.shift());
                
                data.forEach((ele)=>{
                    this.apps.push(ele);
                })
            }

            this.finishLoad();

            if (cb) cb();
          });
        },

        checkReport() {
            if (!GlobalConfig.getInstance().reported) {
                return;
            }
            this.reported = true;
            clearInterval(this.timeId);

            this.refreshAppList();
            this.refreshBanner();
        }
    },

    mounted() {
        this.timeId = setInterval(this.checkReport,100);
    }
}
</script>